<template>
  <div class="home">
    <TrainRouteFinder />
  </div>
</template>

<script>
import TrainRouteFinder from '@/components/TrainRouteFinder.vue'

export default {
  name: 'HomePage',
  components: {
    TrainRouteFinder
  }
}
</script>