<template>
  <div class="title-bar">
    <h1>日暮里舎人ライナーの乗り換え案内</h1>
  </div>

  <div class="train-route-finder">
    <div class="search-panel">
      <select v-model="from" @change="validateInput">
        <option value="">出発駅を選択してください</option>
        <option v-for="station in nipporiToneriStations" :key="station.name" :value="station.station_id">
          {{ station.name }}
        </option>
      </select>
      <select v-model="to" @change="validateInput">
        <option value="">到着駅を選択してください</option>
        <option v-for="station in yamanoteStations" :key="station.name" :value="station.station_id">
          {{ station.name }}
        </option>
      </select>
      <button @click="findRoutes" :disabled="!isValid">経路を検索</button>
    </div>
    <div v-if="loading" class="loading">読み込み中...</div>
    <div v-else-if="error" class="error">{{ error }}</div>
    <div v-if="routes.length > 0" class="results-container">
      <div class="routes-list">
        <div
          v-for="(route, index) in routes"
          :key="index"
          class="route-card"
          @click="toggleDetails(index)"
          :class="{ expanded: expandedIndex === index }"
        >
          <div class="route-details">
            <h2>経路 {{ index + 1 }} {{ route.recommended ? '(おすすめ)' : '' }}</h2>
            <div class="route-info">
              <div class="route-info-row">
                <div class="info-item">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon"><path d="M12 1v22M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"/></svg>
                  <span>¥{{ route.fare }}</span>
                </div>
                <div class="info-item">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon"><path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"/><line x1="7" y1="7" x2="7.01" y2="7"/></svg>
                  <span>¥{{ route.savedFare }}</span>
                </div>
                <div class="info-item">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon"><circle cx="12" cy="12" r="10"/><polyline points="12 6 12 12 16 14"/></svg>
                  <span>{{ route.totalTime }}分</span>
                </div>
              </div>
              <div class="route-info-row">
                <div class="info-item">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon"><path d="M18 20V10"/><path d="M12 20V4"/><path d="M6 20v-6"/></svg>
                  <span>{{ route.caloriesBurned }}kcal</span>
                </div>
                <div class="info-item">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon"><polyline points="16 3 21 3 21 8"/><line x1="4" y1="20" x2="21" y2="3"/><polyline points="21 16 21 21 16 21"/><line x1="15" y1="15" x2="21" y2="21"/><line x1="4" y1="4" x2="9" y2="9"/></svg>
                  <span>{{ route.transfers }}回</span>
                </div>
                <div class="info-item">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"/></svg>
                  <span>{{ route.pointsEarned }}P</span>
                </div>
              </div>
            </div>
            <div v-if="expandedIndex === index" class="route-visualization">
              <div
                v-for="(step, stepIndex) in route.steps"
                :key="stepIndex"
                class="route-step"
              >
                <div class="station">{{ getStationName(step.from_id) }}</div>
                <div class="line" :style="{ backgroundColor: getLineColor(step.line) }">
                  <span class="line-info">
                    <i :class="getLineIcon(step.line)"></i>
                    {{ step.line }} ({{ step.duration }}分)
                  </span>
                </div>
                <div class="station">{{ getStationName(step.to_id) }}</div>
              </div>
            </div>
          </div>
          <div ref="mapContainer" :id="'map-container-' + index" class="map-container"></div>
        </div>
      </div>
    </div>
    <div v-else-if="searched" class="no-routes">
      経路が見つかりませんでした。別の駅を選択してください。
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import L from 'leaflet';

export default {
  name: 'TrainRouteFinder',
  data() {
    return {
      from: '',
      to: '',
      routes: [],
      loading: false,
      error: null,
      isValid: false,
      maps: [],
      searched: false,
      expandedIndex: null,
      stationMap: {
        '1130101': '東京',
        '1130102': '新橋',
        '1130103': '品川',
        '1130201': '大崎',
        '1130202': '五反田',
        '1130203': '目黒',
        '1130204': '恵比寿',
        '1130205': '渋谷',
        '1130206': '原宿',
        '1130207': '代々木',
        '1130208': '新宿',
        '1130209': '新大久保',
        '1130210': '高田馬場',
        '1130211': '目白',
        '1130212': '池袋',
        '1130213': '大塚',
        '1130214': '巣鴨',
        '1130215': '駒込',
        '1130216': '田端',
        '1130217': '西日暮里',
        '1130218': '日暮里',
        '1130219': '鶯谷',
        '1130220': '上野',
        '1130221': '御徒町',
        '1130222': '秋葉原',
        '1130223': '神田',
        '1130225': '有楽町',
        '1130227': '浜松町',
        '1130228': '田町',
        '1130230': '高輪ゲートウェイ',
        '9930509': '熊野前',
        '9934205': '足立小台',
        '9934206': '扇大橋',
        '9934207': '高野',
        '9934208': '江北',
        '9934209': '西新井大師西',
        '9934210': '谷在家',
        '9934211': '舎人公園',
        '9934212': '舎人',
        '9934213': '見沼代親水公園',
      },
      lineMap: {
        'JR東海道本線': '#000080', // 紺色
        'JR山手線': '#9ACD32', // 黄緑色
        'JR中央線(快速)': '#FF4500', // オレンジ色
        'JR中央・総武線': '#FFD700', // ゴールド
        'JR宇都宮線': '#4682B4', // スチールブルー
        'JR常磐線': '#00CED1', // ダークターコイズ
        '東武伊勢崎線': '#D2691E', // チョコレート色
        '東武大師線': '#8B4513', // セピア
        '京成本線': '#1E90FF', // ドジャーブルー
        '東京メトロ千代田線': '#009933', // グリーン
        '日暮里・舎人ライナー': '#00BFFF', // ディープスカイブルー
        '徒歩': '#FFA500', // オレンジ
        '乗り換え': '#FFA500', // オレンジ
      },
      nipporiToneriStations: [
        { name: '日暮里', station_id: '1130218' },
        { name: '西日暮里', station_id: '1130217' },
        { name: '熊野前', station_id: '9930509' },
        { name: '足立小台', station_id: '9934205' },
        { name: '扇大橋', station_id: '9934206' },
        { name: '高野', station_id: '9934207' },
        { name: '江北', station_id: '9934208' },
        { name: '西新井大師西', station_id: '9934209' },
        { name: '谷在家', station_id: '9934210' },
        { name: '舎人公園', station_id: '9934211' },
        { name: '舎人', station_id: '9934212' },
        { name: '見沼代親水公園', station_id: '9934213' }
      ],
      yamanoteStations: [
        { name: '東京', station_id: '1130101' },
        { name: '有楽町', station_id: '1130225' },
        { name: '新橋', station_id: '1130102' },
        { name: '浜松町', station_id: '1130227' },
        { name: '田町', station_id: '1130228' },
        { name: '高輪ゲートウェイ', station_id: '1130230' },
        { name: '品川', station_id: '1130103' },
        { name: '大崎', station_id: '1130201' },
        { name: '五反田', station_id: '1130202' },
        { name: '目黒', station_id: '1130203' },
        { name: '恵比寿', station_id: '1130204' },
        { name: '渋谷', station_id: '1130205' },
        { name: '原宿', station_id: '1130206' },
        { name: '代々木', station_id: '1130207' },
        { name: '新宿', station_id: '1130208' },
        { name: '新大久保', station_id: '1130209' },
        { name: '高田馬場', station_id: '1130210' },
        { name: '目白', station_id: '1130211' },
        { name: '池袋', station_id: '1130212' },
        { name: '大塚', station_id: '1130213' },
        { name: '巣鴨', station_id: '1130214' },
        { name: '駒込', station_id: '1130215' },
        { name: '田端', station_id: '1130216' },
        { name: '西日暮里', station_id: '1130217' },
        { name: '日暮里', station_id: '1130218' },
        { name: '鶯谷', station_id: '1130219' },
        { name: '上野', station_id: '1130220' },
        { name: '御徒町', station_id: '1130221' },
        { name: '秋葉原', station_id: '1130222' },
        { name: '神田', station_id: '1130223' }
      ]
    };
  },
  methods: {
    validateInput() {
      this.isValid = this.from !== '' && this.to !== '';
    },
    async findRoutes() {
      if (!this.isValid) return;
      this.loading = true;
      this.error = null;
      this.routes = [];
      this.maps = [];
      this.searched = true;
      try {
        const response = await axios.get('http://localhost:8000/routes', {
          params: {
            from_id: this.from,
            to_id: this.to,
            topk: 3,
            cost_type: 'time',
          },
        });
        this.routes = response.data;
        if (this.routes.length > 0) {
          await this.$nextTick();
          this.initMaps();
          this.updateMaps();
        }
      } catch (error) {
        this.error = '経路の取得に失敗しました。もう一度お試しください。';
        console.error('API request failed:', error);
      } finally {
        this.loading = false;
      }
    },
    toggleDetails(index) {
      this.expandedIndex = this.expandedIndex === index ? null : index;
      this.$nextTick(() => {
        if (this.expandedIndex !== null) {
          this.updateMaps();
        }
      });
    },
    getStationName(station_id) {
      return this.stationMap[station_id] || station_id;
    },
    getLineColor(lineName) {
      return this.lineMap[lineName] || '#888888'; // デフォルト色をグレーに設定
    },
    getLineIcon(lineName) {
      if (lineName === '徒歩' || lineName === '乗り換え') {
        return 'fas fa-walking'; // 徒歩と乗り換えのアイコン
      }
      return 'fas fa-train'; // その他の路線のアイコン
    },
    initMaps() {
      this.routes.forEach((route, index) => {
        const mapContainerId = 'map-container-' + index;
        const mapContainer = document.getElementById(mapContainerId);
        if (mapContainer) {
          const map = L.map(mapContainerId).setView([35.7280, 139.7780], 12);
          L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          }).addTo(map);
          this.maps.push(map);
        }
      });
    },
    updateMaps() {
      this.$nextTick(() => {
        this.routes.forEach((route, index) => {
          const map = this.maps[index];
          if (!map) return;
          
          map.invalidateSize();
          
          map.eachLayer((layer) => {
            if (layer instanceof L.Marker || layer instanceof L.Polyline) {
              map.removeLayer(layer);
            }
          });
          
          if (route.steps.length > 0) {
            const points = route.steps.flatMap(step => [
              [step.fromLat, step.fromLon],
              [step.toLat, step.toLon]
            ]);
            
            L.polyline(points, {color: 'red'}).addTo(map);
            map.fitBounds(points);

            // 始点のマーカー
            L.marker([route.steps[0].fromLat, route.steps[0].fromLon]).addTo(map)
              .bindPopup(this.getStationName(route.steps[0].from_id)).openPopup();

            // 終点のマーカー
            L.marker([route.steps[route.steps.length - 1].toLat, route.steps[route.steps.length - 1].toLon]).addTo(map)
              .bindPopup(this.getStationName(route.steps[route.steps.length - 1].to_id)).openPopup();
          }
        });
      });
    }
  }
};
</script>



<style>
@import 'leaflet/dist/leaflet.css';

#app, body {
  margin: 0;
  padding: 0;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  box-sizing: border-box;
}

.title-bar, .search-panel select, .search-panel button {
  background-color: #4caf50;
}

.title-bar h1, .search-panel button {
  color: white;
}

.title-bar {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  text-align: center;
  font-size: 10px;
  font-weight: normal;
  margin-bottom: 0px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 0;
}

.title-bar h1 {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
}

.train-route-finder {
  width: 100%;
  max-width: 1200px;
  margin: 10px auto;
  padding: 15px;
  box-sizing: border-box;
  margin-bottom: 0;
  flex-grow: 1;
}

.search-panel {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
  width: 100%;
}

.search-panel select {
  flex-grow: 1;
  padding: 12px;
  border: 2px solid #4caf50;
  border-radius: 4px;
  background-color: white;
  font-size: 16px;
  color: #4caf50;
}

.search-panel button {
  padding: 12px 24px;
  font-size: 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-panel button:hover {
  background-color: #45a049;
}

.results-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: calc(100vh - 200px);
  margin-bottom: 0;
  overflow-y: auto;
}

.routes-list {
  width: 100%;
}

.route-card {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 12px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  cursor: pointer;
  transition: all 0.3s ease;
}

.route-card:hover {
  box-shadow: 0 4px 6px rgba(0,0,0,0.15);
  transform: translateY(-2px);
}

.route-details {
  flex: 1;
  padding-right: 15px;
}

.route-card h2 {
  margin: 0;
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

.route-info {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px 15px;
  justify-content: start;
}

.info-item {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 15px;
}

.info-item .icon {
  width: 20px;
  height: 20px;
  color: #4caf50;
  flex-shrink: 0;
}

.info-item span {
  font-size: 20px;
  color: #333;
  font-weight: bold;
}

.route-visualization {
  margin-top: 15px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.route-step {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.station {
  background-color: #4caf50;
  color: white;
  padding: 6px 12px;
  border-radius: 15px;
  font-size: 12px;
  z-index: 1;
}

.line {
  flex-grow: 1;
  height: 4px;
  margin: 0 -10px;
  position: relative;
  z-index: 0;
}

.line-info {
  position: absolute;
  top: -14px;
  left: 50%;
  transform: translateX(-50%);
  background-color: inherit;
  padding: 2px 8px;
  border-radius: 10px;
  font-size: 12px;
  white-space: nowrap;
  color: white;
}

.line-info i {
  margin-right: 5px;
}

.map-container {
  width: 40%;
  height: 200px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
}

.expanded .map-container {
  height: 300px;
}

.loading, .error, .no-routes {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
}

.error, .no-routes {
  color: #d32f2f;
}

@media (max-width: 768px) {
  .search-panel {
    flex-direction: column;
  }

  .route-card {
    flex-direction: column;
  }

  .route-details {
    padding-right: 0;
    margin-bottom: 15px;
  }

  .map-container {
    width: 100%;
    height: 150px;
  }

  .expanded .map-container {
    height: 250px;
  }

  .route-info {
    grid-template-columns: repeat(2, 1fr);
  }
}

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');
</style>