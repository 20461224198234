<template>
  <div id="app">
    <TrainRouteFinder />
  </div>
</template>

<script>
import TrainRouteFinder from './components/TrainRouteFinder.vue'

export default {
  name: 'App',
  components: {
    TrainRouteFinder
  }
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
}

#app {
  display: flex;
  flex-direction: column; /* 子要素を縦に並べる */
  align-items: center; /* 子要素を中央揃えにする */
  min-height: 100vh;
  width: 100%;
  padding: 0px;
  box-sizing: border-box;
}

/* Leaflet styles */
@import 'leaflet/dist/leaflet.css';
</style>